import { InstagramEmbed } from 'react-social-media-embed'

interface InstagramProps {
  url: string
  className?: string
}

export const Instagram = ({ url, className }: InstagramProps) => (
  <InstagramEmbed url={url} width="100%" className={className} />
)
