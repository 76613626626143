import type { SerializedElementNode } from '../types'
import type { SerializedUploadNode } from '@payloadcms/richtext-lexical'
import type { Media } from 'payload/generated-types'

import React from 'react'

import { Blurhash } from '@/components/blurhash'
import { cn } from '@/lib/utils'

export const Upload: React.FC<SerializedElementNode & SerializedUploadNode> = ({ value, className }) => {
  /* @ts-ignore */
  const media: Media = value
  return (
    <figure className={cn("my-6 flex flex-col gap-2 lg:gap-3", className)}>
      <Blurhash hash={media.blurhash} className="rounded-lg">
        <img className="rounded-lg" src={media.url ?? ''} alt={media.alt} width={String(media.width)} height={String(media.height)} loading="lazy" />
      </Blurhash>
      {media.caption && (
        <figcaption className="self-end text-xs text-primary/80 not-prose">{media.caption}</figcaption>
      )}
    </figure>
  )
}