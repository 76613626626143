import type { SerializedElementNode } from '../types'
import type { SerializedListNode } from '@lexical/list'

import React from 'react'

import { SerializeLexical } from '../serialize-lexical'

type ListTag = Extract<keyof JSX.IntrinsicElements, 'ol' | 'ul'>

export const List: React.FC<SerializedListNode & SerializedElementNode> = ({ tag, children, className }) => {
  const Tag = tag as ListTag
  return (
    <Tag className={className} >
      {SerializeLexical(children as SerializedElementNode[])}
    </Tag>
  )
}