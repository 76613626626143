import { FacebookEmbed } from 'react-social-media-embed'

interface FacebookProps {
  url: string
  className?: string
}

export const Facebook = ({ url, className }: FacebookProps) => (
  <FacebookEmbed url={url} width="100%" className={className} />
)
