import type { SerializedElementNode } from './types'

import React, { Fragment } from 'react'

import { cn } from '@/lib/utils'

import { Nodes } from './nodes'

const INDENT_CLASSES: Record<number, string> = {
  0: '',
  1: 'ml-8',
  2: 'ml-16',
  3: 'ml-24',
  4: 'ml-32',
  5: 'ml-40'
}

const FORMAT_CLASSES: Record<string, string> = {
  '': '',
  'left': 'text-left',
  'center': 'text-center',
  'right': 'text-right',
  'justify': 'text-justify'
}

const formatClassnames = ({ indent, format }: SerializedElementNode) => cn(INDENT_CLASSES[indent], FORMAT_CLASSES[format])

export const SerializeLexical: React.FC<SerializedElementNode[]> = (nodes) => (
  <Fragment>
    {nodes?.map((node, index) => {
      const NodeComponent = Nodes[node.type]
      if (!NodeComponent) {
        console.log(`No component found for node type "${node.type}"`)
        return null
      }
      return <NodeComponent key={index} index={index} {...node} className={formatClassnames(node)} />
    })}
  </Fragment>
)
