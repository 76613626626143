import { ChevronUp } from "lucide-react"

import { cn } from "@/lib/utils"

interface FaqProps {
  className?: string;
  title: string;
  faqs: {
    id: string;
    title: string;
    answer: string;
  }[];
}

export const Faq = ({ title, faqs, className }: FaqProps) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer,
      },
    })),
  }

  return (
    <>
      <h2 className="text-xl">{title}</h2>
      <ul className={cn("flex flex-col not-prose border-t mb-8", className)}>
        {faqs.map(({ id, title, answer }, index) => (
          <li key={id} className="flex flex-col gap-2 border-b py-4">
            <details className="group">
              <summary
                className="flex gap-2 items-center focus:outline-none cursor-pointer"
                aria-controls={`faq-${index}`}
              >
                <h3 className="grow text-left font-medium text-primary">{title}</h3>
                <div className="shrink-0 aspect-square rounded-full p-1.5 justify-center items-center flex transform transition-transform duration-300">
                  <span className="sr-only">Expandir</span>
                  <ChevronUp className="w-6 h-6 transform transition-transform duration-300 group-open:rotate-180" />
                </div>
              </summary>
              <p id={`faq-${index}`} className="mt-2 text-primary/80 group-open:animate-fadeIn">
                {answer}
              </p>
            </details>
          </li>
        ))}
      </ul>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
    </>
  )
}