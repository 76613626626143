import { XEmbed } from 'react-social-media-embed'

interface XProps {
  url: string
  className?: string
}

export const X = ({ url, className }: XProps) => (
  <XEmbed url={url} width="100%" className={className} />
)
