import { YouTubeEmbed } from 'react-social-media-embed'

import { cn } from '@/lib/utils'

interface YoutubeProps {
  url: string
  title: string
  className?: string
}

export const Youtube = ({ url, className }: YoutubeProps) => (
  <YouTubeEmbed url={url} className={cn("rounded-md", className)} width="100%" />
)
