import { PinterestEmbed } from 'react-social-media-embed'

interface PinterestProps {
  url: string
  className?: string
}

export const Pinterest = ({ url, className }: PinterestProps) => (
  <PinterestEmbed url={url} width="100%" className={className} />
)
