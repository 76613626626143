import { Block } from './block'
import { Heading } from './heading'
import { Label } from './label'
import { Linebreak } from './linebreak'
import { Link } from './link'
import { List } from './list'
import { ListItem } from './listitem'
import { Paragraph } from './paragraph'
import { Quote } from './quote'
import { Relationship } from './relationship'
import { Text } from './text'
import { Upload } from './upload'

export const Nodes: Record<string, React.ElementType> = {
  'block': Block,
  'heading': Heading,
  'label': Label,
  'linebreak': Linebreak,
  'list': List,
  'paragraph': Paragraph,
  'text': Text,
  'quote': Quote,
  'listitem': ListItem,
  'link': Link,
  'upload': Upload,
  'relationship': Relationship,
}