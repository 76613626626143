import { Facebook } from './facebook'
import { Faq } from './faq'
import { Gallery } from './gallery'
import { Instagram } from './instagram'
import { Pinterest } from './pinterest'
import { TikTok } from './tiktok'
import { X } from './x'
import { Youtube } from './youtube'

export const Blocks: Record<string, React.ElementType> = {
  'facebookBlock': Facebook,
  'galleryBlock': Gallery,
  'instagramBlock': Instagram,
  'pinterestBlock': Pinterest,
  'tiktokBlock': TikTok,
  'xBlock': X,
  'youtubeBlock': Youtube,
  'faqBlock': Faq,
}