import type { Media } from "payload/generated-types"

import { Blurhash } from '@/components/blurhash'
import { cn } from "@/lib/utils"

interface GalleryProps {
  className?: string
  items: {
    id: string
    media: Media
  }[]
}

const GRID_SIZE_CLASSES: Record<number, string> = {
  1: 'grid-cols-1',
  2: 'grid-cols-2 lg:grid-cols-2',
  3: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-3',
  4: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-2',
}

export const Gallery = ({ items, className }: GalleryProps) => {
  const gridSizeClass = GRID_SIZE_CLASSES[items.length] ?? GRID_SIZE_CLASSES[3]
  return (
    <div className={cn("grid gap-4 not-prose my-8", gridSizeClass, className)}>
      {items.map(({ media, id }) => (
        <div key={id} className="flex flex-col gap-2 md:gap-3">
          <Blurhash hash={media.blurhash} className="rounded-lg" figureClassName="h-full">
            <img className="rounded-lg h-full object-cover object-center" src={media.url ?? ''} alt={media.alt} width={String(media.width)} height={String(media.height)} />
          </Blurhash>
          {media.caption && (
            <figcaption className="self-end text-xs text-primary/80 not-prose">{media.caption}</figcaption>
          )}
        </div>
      ))}
    </div>
  )
}