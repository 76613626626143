import type { SerializedTextNode } from 'lexical'

import escapeHTML from 'escape-html'
import React from 'react'
import { useInView } from "react-intersection-observer"

import { cn } from '@/lib/utils'

import { IS_BOLD, IS_ITALIC, IS_STRIKETHROUGH, IS_UNDERLINE, IS_CODE, IS_SUBSCRIPT, IS_SUPERSCRIPT, IS_HIGHLIGHT } from './node-format'

const TextFormatter = ({ children, format }: { children: React.ReactNode, format: number }) => {
  let text = (
    <span dangerouslySetInnerHTML={{ __html: children as any }}></span>
  )
  if (format & IS_BOLD) {
    text = <strong className="font-bold">{text}</strong>
  }
  if (format & IS_ITALIC) {
    text = <em>{text}</em>
  }
  if (format & IS_HIGHLIGHT) {
    text = <HighlightText>{text}</HighlightText>
  }
  if (format & IS_STRIKETHROUGH) {
    text = (
      <span className="line-through">
        {text}
      </span>
    )
  }
  if (format & IS_UNDERLINE) {
    text = (
      <span className="underline">
        {text}
      </span>
    )
  }
  if (format & IS_CODE) {
    text = <code>{text}</code>
  }
  if (format & IS_SUBSCRIPT) {
    text = <sub>{text}</sub>
  }
  if (format & IS_SUPERSCRIPT) {
    text = <sup>{text}</sup>
  }
  return text
}

const HighlightText = ({ children }: { children: React.ReactNode }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1
  })
  return (
    <span ref={ref} className={cn("highlight", inView && "highlighted")}>{children}</span>
  )
}

export const Text: React.FC<SerializedTextNode> = ({ text, format }) => {
  const textContent = escapeHTML(text)
  return (
    <TextFormatter format={format}>
      {textContent}
    </TextFormatter>
  )
}
