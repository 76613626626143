import type { SerializedElementNode } from '../types'
import type { SerializedHeadingNode } from '@lexical/rich-text'

import React from 'react'

import { SerializeLexical } from '../serialize-lexical'
type HeadingTag = Extract<keyof JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5'>

export const Heading: React.FC<SerializedHeadingNode & SerializedElementNode> = ({ tag, children, className }) => {
  const Tag = tag as HeadingTag
  return (
    <Tag className={className} >
      {SerializeLexical(children as SerializedElementNode[])}
    </Tag>
  )
}