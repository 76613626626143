import type { SerializedElementNode } from '../types'
import type { SerializedListItemNode } from '@lexical/list'

import React from 'react'

import { cn } from '@/lib/utils'

import { SerializeLexical } from '../serialize-lexical'

export const ListItem: React.FC<SerializedElementNode & SerializedListItemNode> = ({ checked, value, children, className }) => {
  if (checked != null) {
    return (
      <li
        aria-checked={checked ? 'true' : 'false'}
        className={cn(className, `component--list-item-checkbox ${checked
          ? 'component--list-item-checkbox-checked'
          : 'component--list-item-checked-unchecked'
          }`)}
        role="checkbox"
        tabIndex={-1}
        value={value}
      >
        {SerializeLexical(children as any)}
      </li>
    )
  } else {
    return (
      <li className={className}>
        {SerializeLexical(children as any)}
      </li>
    )
  }
}