import { cn } from '@/lib/utils'

import { SerializeLexical } from './serialize-lexical'

export const RichText: React.FC<{ className?: string; content?: any }> = ({ className, content }) => {
  if (!content) {
    return null
  }

  return (
    <div className={cn("prose dark:prose-invert dark:text-gray-200", className)}>
      {content &&
        !Array.isArray(content) &&
        typeof content === 'object' &&
        'root' in content &&
        SerializeLexical(content?.root?.children)}
    </div>
  )
}