import type { SerializedElementNode } from '../types'
import type { LinkFields, SerializedLinkNode } from '@payloadcms/richtext-lexical'

import { Link as RemixLink } from '@remix-run/react'
import escapeHTML from 'escape-html'
import React from 'react'

import { SerializeLexical } from '../serialize-lexical'

export const Link: React.FC<SerializedElementNode & SerializedLinkNode> = ({ children, fields, className }) => {
  const { linkType, url, newTab, doc }: LinkFields = fields
  const docValue = doc?.value
  const slug = typeof docValue === 'string' ? docValue : docValue?.slug
  const to = escapeHTML(linkType === 'custom' ? url : `/${slug}`)
  return (
    <RemixLink to={to} className={className}
      {...(newTab
        ? {
          rel: 'noopener noreferrer',
          target: '_blank',
        }
        : {})}>
      {SerializeLexical(children as SerializedElementNode[])}
    </RemixLink>
  )
}