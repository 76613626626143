import type { SerializedElementNode } from '../types'

import React from 'react'

import { Blocks } from "@/components/blocks"

type SerializedBlockNode = {
  fields: {
    blockType: string
  }
}

export const Block: React.FC<SerializedElementNode & SerializedBlockNode> = ({ className, fields }) => {
  const Tag = Blocks[fields.blockType]
  if (!Tag) return null
  return (
    <Tag className={className} {...fields} />
  )
}