import type { SerializedElementNode } from '../types'

import React from 'react'

import { SerializeLexical } from '../serialize-lexical'

export const Paragraph: React.FC<SerializedElementNode> = ({ children, className }) => (
  <p className={className}>
    {SerializeLexical(children as SerializedElementNode[])}
  </p>
)
