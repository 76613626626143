import { TikTokEmbed } from 'react-social-media-embed'

import { cn } from '@/lib/utils'

interface TikTokProps {
  url: string
  className?: string
}

export const TikTok = ({ url, className }: TikTokProps) => (
  <TikTokEmbed url={url} width="100%" className={cn(className, "not-prose")} />
)
