import type { SerializedElementNode } from '../types'
import type { SerializedRelationshipNode } from '@payloadcms/richtext-lexical'

import { Link } from '@remix-run/react'
import React from 'react'

import { cn } from '@/lib/utils'

export const Relationship: React.FC<SerializedElementNode & SerializedRelationshipNode> = ({ relationTo, value, className }) => {
  if (relationTo === "posts") {
    /* @ts-ignore */
    const { title, slug } = value as { title: string, slug: string }
    return (
      <div className={cn("flex gap-3", className)}>
        <div className='w-2 min-w-2 h-2 rounded-[2px] bg-indigo-700 mt-[0.65rem]'></div>
        <Link to={`/${slug}`} className='underline underline-offset-2 decoration-2 decoration-indigo-700'>
          <mark className='bg-transparent hover:bg-indigo-100 transition-colors'>{title}</mark>
        </Link>
      </div>
    )
  }
  return null
}